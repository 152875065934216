import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { useCtx } from '../Context/';
import { useTranslation } from '../Context/Translations';
import MenuButton from './MenuButton';
import SearchBar from './SearchBar';
import Content from './Content';
import './menu.scss';
// import { isMobile } from '../../utils';
import { MapItem, SubzoneType } from '../../types';

const Menu = (): JSX.Element => {
  const location = useLocation();

  const [menuOpen, setMenuOpen] = useState(location.pathname === '/'); // Menu open on desktop if frontpage
  const [classes, setClasses] = useState('');
  // const [overflowing, setOverflowing] = useState(false);
  const { selectedSubzone, markers, routes, areas, subzones } = useCtx();
  const { translate, currentLanguage } = useTranslation();

  const toggleMenu = (val: boolean): void => {
    const search: HTMLInputElement = document.getElementById(
      'search'
    ) as HTMLInputElement;
    const cont = document.getElementById('results-container');
    search.value = '';
    cont?.classList.add('hidden');
    setMenuOpen(val);
  };

  const search = (val: string): Array<MapItem | SubzoneType> | [] => {
    if (val.length < 1) {
      return [];
    }

    let toSearch = [];
    // Search from subzones on main page
    if (classes === 'main-page') {
      toSearch = subzones;
    } else {
      // Search from all map items if in subzone
      toSearch = markers.concat(routes, areas);
      // Filter interactive mapitems to search
      toSearch = toSearch.filter(i => i.isInteractive);
    }

    return (toSearch as Array<MapItem | SubzoneType>).filter(
      (s: MapItem | SubzoneType) => {
        const title = s.i18n[currentLanguage]?.title || s.title;
        return title.toLowerCase().includes(val.toLowerCase());
      }
    );
  };

  useEffect(() => {
    if (selectedSubzone === null) {
      setClasses('main-page');
    } else {
      setClasses('subzone');
    }
  }, [selectedSubzone]);

  // Set menu hidden on first render on mobile
  useEffect(() => {
    if (location.pathname !== '/') {
      const menuEl = document.getElementsByClassName('menu__list')[0];
      menuEl.classList.add('hidden');
      setMenuOpen(false);
    }
  }, [location.pathname]);

  // Set searchbar hidden on first render on mobile
  useEffect(() => {
    if (location.pathname !== '/') {
      const searchEl = document.getElementsByClassName('search-container')[0];
      searchEl.classList.add('hidden');
      setMenuOpen(false);
    }
  }, [location.pathname]);

  // Set menu hidden after animation when closing the menu
  useEffect(() => {
    if (!menuOpen) {
      const menu = document.getElementsByClassName('menu__list')[0];
      setTimeout(() => {
        menu.classList.add('hidden');
      }, 380);
    }
  }, [menuOpen]);

  // Set seachbar hidden after animation when closing the menu
  useEffect(() => {
    if (!menuOpen) {
      const search = document.getElementsByClassName('search-container')[0];
      setTimeout(() => {
        search.classList.add('hidden');
      }, 380);
    }
  }, [menuOpen]);

  // useEffect(() => {
  //   const el = document.getElementById('list');
  //   const scrollHeight = el?.scrollHeight;
  //   const clientHeight = el?.clientHeight;
  //   if (scrollHeight && clientHeight) {
  //     if (scrollHeight > clientHeight && menuOpen) {
  //       setOverflowing(true);
  //     } else {
  //       setOverflowing(false);
  //     }
  //   }
  // }, [menuOpen]);
  return (
    <div className="menu">
      <MenuButton menuOpen={menuOpen} open={toggleMenu} classes={classes} />
      <SearchBar
        toggleMenu={toggleMenu}
        search={search}
        placeholderText={translate('infobox-search')}
        classes={menuOpen ? 'out' : 'in'}
      />
      <div className={`menu__list ${menuOpen ? 'in' : 'out'}`} id="list">
        <div className="content-wrapper">
          <Content />
        </div>
        <div className={`gradient ${menuOpen ? 'in' : ''}`}></div>
      </div>
    </div>
  );
};

export default Menu;
