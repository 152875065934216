import React, { useCallback, useContext, useEffect, useState } from 'react';

const TranslationContext = React.createContext({
  translate: (text: string): string => text,
  currentLanguage: ''
});

const FALLBACK_LANGUAGE = 'fi';
const translations = {
  fi: {
    'subzonepopup-link-text': 'Katso reittiohjeet',
    'subzonepopup-button-text': 'Tutustu alueeseen',
    'infobox-link-text': 'Alueen tiedot',
    'infobox-search': 'Etsi kohteen nimellä...',
    'infobox-conservation-area': 'Luonnonsuojelualue',
    'menu-area-button': 'Valitse alue',
    'menu-activity-button': 'Valitse aktiviteetti',
    'forest-fire-card-title': 'Maastopalovaroitus!',
    'forest-fire-card-description':
      'Maastopalovaroitus on voimassa ja avotulenteko on kielletty. \n\r\n\rTarkemmat ohjeet tulentekoon löydät retkikohteen tiedoista.\n\r\n\r',
    'forest-fire-card-link-url': 'https://www.ilmatieteenlaitos.fi/varoitukset',
    'forest-fire-card-link-text': 'Ilmatieteen laitos',
    'current-location-success': 'Nykyisen sijainnin määrittäminen onnistui',
    'current-location-error': 'Nykyisen sijainnin määrittäminen epäonnistui',
    'current-location-outside': 'Nykyinen sijainti alueen ulkopuolella',
    attachment: 'Liitteet:',
    'overlay-frontpage': 'Tutustu alueisiin',
    'overlay-subzone': 'Tutustu alueeseen',
    'esteeton-kohde': 'Esteetön kohde', // Hard coded tag translations
    'julkinen-liikenne': 'Julkinen liikenne',
    paakaupunkiseutu: 'Pääkaupunkiseutu',
    'lansi-uusimaa': 'Länsi-Uusimaa',
    'keski-uusimaa': 'Keski-Uusimaa',
    'ita-uusimaa': 'Itä-Uusimaa',
    merenranta: 'Merenranta',
    saari: 'Saari',
    'reitti-alle-3km': 'Reitti alle 3 km',
    latuhiihto: 'Latuhiihto',
    'helppo-kulkeminen': 'Helppo kulkeminen',
    retkipyoraily: 'Retkipyöräily',
    tulistelu: 'Tulistelu',
    kavely: 'Kävely',
    veneily: 'Veneily',
    melonta: 'Melonta',
    maastopyoraily: 'Maastopyöräily',
    kalastus: 'Kalastus',
    uiminen: 'Uiminen',
    'reitti-yli-3km': 'Reitti yli 3 km',
    talviretkeily: 'Talviretkeily',
    'sienestys-ja-marjastus': 'Sienestys ja marjastus',
    'lintujen-tarkkailu': 'Lintujen tarkkailu' // Hard coded tags END
  },
  en: {
    'subzonepopup-link-text': 'Driving directions',
    'subzonepopup-button-text': 'Explore the destination',
    'infobox-link-text': 'Destination information',
    'infobox-search': 'Search by destination name…',
    'infobox-conservation-area': 'Nature conservation area',
    'menu-area-button': 'Choose destination',
    'menu-activity-button': 'Choose activity',
    'forest-fire-card-title': 'Wildfire warning!',
    'forest-fire-card-description':
      'Wildfire warnings are in place for the area and making a fire is prohibited. Check destination’s page for specific information.',
    'forest-fire-card-link-url': 'https://en.ilmatieteenlaitos.fi/',
    'forest-fire-card-link-text': 'Finnish Meteorological Institute',
    'current-location-success': 'Current location found successfully',
    'current-location-error': 'Error accessing current location',
    'current-location-outside': 'Current location outside area',
    attachment: 'Attachments:',
    'overlay-frontpage': 'Explore areas',
    'overlay-subzone': 'Explore area',
    'esteeton-kohde': 'Accessible destination', // Hard coded tag translations
    'julkinen-liikenne': 'Public transportation',
    paakaupunkiseutu: 'Capital region',
    'lansi-uusimaa': 'Western Uusimaa',
    'keski-uusimaa': 'Middle Uusimaa',
    'ita-uusimaa': 'Eastern Uusimaa',
    merenranta: 'Coastal area',
    saari: 'Island',
    'reitti-alle-3km': 'Under 3 km trail',
    latuhiihto: 'Cross-country skiing track',
    'helppo-kulkeminen': 'Easy hiking',
    retkipyoraily: 'Tour biking',
    tulistelu: 'Campfire',
    kavely: 'Walking',
    veneily: 'Boating',
    melonta: 'Paddling',
    maastopyoraily: 'Mountain biking',
    kalastus: 'Fishing',
    uiminen: 'Swimming',
    'reitti-yli-3km': 'Over 3 km trail',
    talviretkeily: 'Winter hiking',
    'sienestys-ja-marjastus': 'Berry and mushroom picking',
    'lintujen-tarkkailu': 'Bird watching' // Hard coded tags END
  },
  sv: {
    'subzonepopup-link-text': 'Kolla vägbeskrivningen',
    'subzonepopup-button-text': 'Lär känna området',
    'infobox-link-text': 'Information om området',
    'infobox-search': 'Sök med områdets namn…',
    'infobox-conservation-area': 'Naturskydsområde',
    'menu-area-button': 'Välj områdena',
    'menu-activity-button': 'Välj aktivitet',
    'forest-fire-card-title': 'Varning för terrängbrand!',
    'forest-fire-card-description':
      'Varning för terrängbrand gäller och det är förbjudet att göra upp eld på eldplatserna. Kontrollera information i anslutning till att göra upp eld i uppgifterna om destinationen.',
    'forest-fire-card-link-url': 'https://sv.ilmatieteenlaitos.fi/',
    'forest-fire-card-link-text': 'Meteorologiska institutet',
    'current-location-success': 'Nykyisen sijainnin määrittäminen onnistui',
    'current-location-error': 'Nykyisen sijainnin määrittäminen epäonnistui',
    'current-location-outside': 'Nykyinen sijainti alueen ulkopuolella',
    attachment: 'Liitteet:',
    'overlay-frontpage': 'Explore areas',
    'overlay-subzone': 'Explore area',
    'esteeton-kohde': 'Tillgängligt för rörelsehindrade', // Hard coded tag translations
    'julkinen-liikenne': 'Kollektivtrafik',
    paakaupunkiseutu: 'Huvudstadsregionen',
    'lansi-uusimaa': 'Västra Nyland',
    'keski-uusimaa': 'Mellersta Nyland',
    'ita-uusimaa': 'Östra Nyland',
    merenranta: 'Havsstrand',
    saari: 'Ö',
    'reitti-alle-3km': 'Under 3 km lång led',
    latuhiihto: 'Skidåkning i skidspår',
    'helppo-kulkeminen': 'Lätt att ta sig fram',
    retkipyoraily: 'Långfärdscykling',
    tulistelu: 'Att görä upp eld',
    kavely: 'Gång',
    veneily: 'Båtliv',
    melonta: 'Paddling',
    maastopyoraily: 'Bergscykling',
    kalastus: 'Fiske',
    uiminen: 'Simning',
    'reitti-yli-3km': 'Över 3km lång led',
    talviretkeily: 'Vinterutflykter',
    'sienestys-ja-marjastus': 'Bär- och svampplocking',
    'lintujen-tarkkailu': 'Fågelskådning' // Hard coded tags END
  }
};

type QueryParamsProps = {
  lang?: string;
};

const getQueryParams = (): QueryParamsProps =>
  window.location.search
    .replace('?', '')
    .split('&')
    .reduce(
      // eslint-disable-next-line
      (r, e) => ((r[e.split('=')[0]] = decodeURIComponent(e.split('=')[1])), r),
      {}
    );

const TranslationProvider = ({ children }: any): JSX.Element => {
  const [language, setLanguage] = useState<string>('');
  const translate = useCallback(
    (text: string) => translations[language || FALLBACK_LANGUAGE][text] || text,
    [language]
  );

  useEffect(() => {
    const params = getQueryParams();
    if (params?.lang && translations[params?.lang.toLowerCase().trim()]) {
      setLanguage(params?.lang.toLowerCase().trim());
    }
  }, [setLanguage]);

  return (
    <TranslationContext.Provider
      value={{
        translate,
        currentLanguage: language
      }}
    >
      {children}
    </TranslationContext.Provider>
  );
};

const useTranslation = () => useContext(TranslationContext);

export { TranslationProvider, TranslationContext, useTranslation };
export default TranslationContext;
