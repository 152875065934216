import React from 'react';
import { Zicon } from 'zoneatlas-icons';

import './menuButton.scss';

// Burger menu button component
type ButtonProps = {
  menuOpen: boolean;
  open: (val: boolean) => void;
  classes: string;
};

const MenuButton = ({ menuOpen, open, classes }: ButtonProps): JSX.Element => (
  <div
    className={`toggle-menu ${menuOpen ? 'toggle-menu--open' : ''}`}
    onClick={(): void => open(!menuOpen)}
  >
    <Zicon
      icon={
        menuOpen
          ? 'ui/chevron/big-left'
          : classes === 'main-page'
          ? 'ui/settings-active'
          : 'category/info-board'
      }
      color="#015168"
      customClass={`toggle-menu__icon ${
        menuOpen ? 'toggle-menu__icon--open' : ''
      }`}
    />
  </div>
);

export default MenuButton;
