import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import axios from 'axios';

import 'leaflet/dist/leaflet.css';
import './App.scss';

import Map from './components/Map';
import Card from './components/Card';
import Menu from './components/Menu';
import WeatherWarnings from './components/WeatherWarnings';
// import EmbedOverlay from './components/EmbedOverlay';

import { AppContextProvider } from './components/Context';
import { TranslationProvider } from './components/Context/Translations';

axios.defaults.headers.common['x-container'] = process.env.REACT_APP_ZONE_ID;

function App(): JSX.Element {
  return (
    <div className="App">
      <TranslationProvider>
        <AppContextProvider>
          <Router>
            <WeatherWarnings />
            <Card />
            <Menu />
            <Switch>
              <Route path="/:subzone/:mapitem">
                <Map />
              </Route>
              <Route path="/:subzone">
                <Map />
              </Route>
              <Route exact path="/">
                <Map />
              </Route>
            </Switch>
          </Router>
        </AppContextProvider>
      </TranslationProvider>
    </div>
  );
}

export default App;
