import React, { useState, useRef, useEffect } from 'react';
import { Zicon } from 'zoneatlas-icons';

import './Accordion.scss';

type Props = {
  id?: string;
  className?: string;
  open?: boolean;
  onClose?: Function;
  children: JSX.Element;
  title: JSX.Element;
};

function Accordion(props: Props): JSX.Element {
  const [active, setActive] = useState<boolean>(!!props.open);
  const [height, setHeight] = useState('0px');

  const content = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (props.open) {
      setActive(true);
      setHeight(`${content.current?.scrollHeight}px`);
    }
  }, [props.open, props.children]);

  const toggleAccordion = (): void => {
    setActive(!active);
    setHeight(active ? '0px' : `${content.current?.scrollHeight}px`);
  };

  return (
    <div className="accordion-container">
      <div
        className={`accordion ${active ? 'active' : ''} ${props.className ||
          ''}`}
      >
        <div className="accordion__title" onClick={toggleAccordion}>
          <span>{props.title}</span>
          <Zicon customClass="accordion__icon" icon="ui/triangle/small-down" />
        </div>
        <div
          ref={content}
          style={{ maxHeight: `${height}` }}
          className="accordion__content"
        >
          <div className="accordion__body">{props.children}</div>
        </div>
      </div>
    </div>
  );
}

export default Accordion;
