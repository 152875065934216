import React, { useState } from 'react';

import ZIcon from 'zoneatlas-icons';
import { useTranslation } from '../Context/Translations';
import { useCtx } from '../Context';
import Card from '../Card';

import './WeatherWarnings.scss';

const CardContent = ({
  open,
  toggleCard
}: {
  open: boolean;
  toggleCard: () => void;
}): JSX.Element | null => {
  const { translate } = useTranslation();

  if (!open) {
    return null;
  }

  return (
    <Card>
      <div className="weather-warnings-card card__content">
        <button type="button" className="close-btn" onClick={toggleCard}>
          ×
        </button>
        <div className="card__header">
          <ZIcon icon="category/forest-fire" customClass="cat-icon" />
        </div>
        <div className="card__content-wrapper">
          <h2 className="card__title">{translate('forest-fire-card-title')}</h2>
          <div className="card__description">
            {translate('forest-fire-card-description')}
          </div>
          <a
            target="_blank"
            rel="noopener noreferrer"
            className="card__link"
            href={translate('forest-fire-card-link-url')}
          >
            {translate('forest-fire-card-link-text')}
            <ZIcon icon="ui/arrow/right-long" customClass="link-arrow" />
          </a>
        </div>
      </div>
    </Card>
  );
};

const WeatherWarnings = (): JSX.Element | null => {
  const [cardOpen, setCardOpen] = useState(window.innerWidth > 900); // Default open if desktop
  const [buttonClicked, setClicked] = useState(window.innerWidth > 900); // Default open if desktop
  const { weatherWarnings, map } = useCtx();
  const wrapperRef = React.useRef<HTMLDivElement>(null);

  const cardOnClose = (): void => {
    if (map && map.current && map.current.container) {
      map.current.container.classList.remove('up');
      map.current.leafletElement.invalidateSize();
      setCardOpen(false);
    }
  };

  React.useEffect(() => {
    function handleClickOutside(event: MouseEvent): void {
      if (
        wrapperRef.current &&
        !wrapperRef.current.contains(event.target as Node) &&
        cardOpen
      ) {
        cardOnClose();
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return (): void => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  });

  if (!weatherWarnings || weatherWarnings.length < 1) {
    return null;
  }

  return (
    <div ref={wrapperRef}>
      <CardContent open={cardOpen} toggleCard={cardOnClose} />
      <div className="weather-warnings-btn-holder">
        <button
          className={`weather-warnings-btn ${buttonClicked ? '' : 'animate'}`}
          onClick={(): void => {
            setCardOpen(true);
            setClicked(true);
          }}
        >
          <ZIcon icon="category/forest-fire" />
        </button>
      </div>
    </div>
  );
};

export default WeatherWarnings;
