import React from 'react';
import { Marker, Tooltip } from 'react-leaflet';
import L from 'leaflet';
import { Zicon } from 'zoneatlas-icons';
import ReactDOMServer from 'react-dom/server';
import { useHistory } from 'react-router-dom';

import { useCtx } from '../../Context';
import { MapItem, MapRefType, Coordinate, Category } from '../../../types';
import { isMobile } from '../../../utils';

import './Markers.scss';
import { useTranslation } from '../../Context/Translations';

type iconProps = {
  category: string;
  selected: boolean;
  zicon: string;
  color: string;
  id: number;
};

const markerIcon = ({
  category,
  selected,
  zicon,
  color,
  id
}: iconProps): L.DivIcon => {
  const iconWidth = 48;
  const iconHeight = 48;
  const iconSize = [iconWidth, iconHeight];
  const iconAnchor = [iconWidth / 2, iconHeight];
  let icon = '';
  if (color === '' || !color) {
    color = '#015168';
  }
  if (category === '') {
    icon = ReactDOMServer.renderToString(
      <Zicon icon="ui/location-blank" color={color} customClass="icon" />
    );
  } else {
    icon = ReactDOMServer.renderToString(
      <>
        <Zicon icon="ui/location-blank" color={color} customClass="icon" />
        <Zicon icon={zicon} color="#fff" customClass="bg" />
      </>
    );
  }
  const options: object = {
    className: `marker-icon marker-icon--${category} marker-icon--marker-${id} ${
      selected ? 'selected' : ''
    }`,
    html: `${icon}`,
    iconSize,
    iconAnchor
  };
  return L.divIcon(options);
};

type clickProps = {
  selectMarker: (mark: MapItem) => void;
  marker: MapItem;
  map: MapRefType | null;
};

const handleClick = ({ selectMarker, marker, map }: clickProps): void => {
  selectMarker(marker);
  if (map && map.current) {
    const mapElem = map.current.leafletElement;
    const currentZoom = mapElem.getZoom();
    const zoom = currentZoom <= 18 ? 18 : currentZoom;

    const px = mapElem.project(marker.geo.coordinates as Coordinate, zoom);

    if (isMobile) {
      // 'card' takes 60% of window height -> project coordinates to 40% window height.
      px.y += window.innerHeight * 0.2;
    }

    mapElem.setView(mapElem.unproject(px, zoom), zoom);
  }
};

const Markers = (): JSX.Element => {
  const history = useHistory();
  const {
    markers,
    selectMarker,
    selectedMarker,
    selectedSubzone,
    categories,
    map
  } = useCtx();
  const { currentLanguage } = useTranslation();

  return (
    <>
      {markers.map((d: MapItem, i: number) => {
        if (d.type !== 'Point') {
          return null;
        }

        // check if current time is more than r.activeTimeStart

        if (d.activeTimeStart && d.activeTimeEnd) {
          const now = new Date();

          const start = new Date(d.activeTimeStart);

          const end = new Date(d.activeTimeEnd);

          if (start > now) return null;
          if (end < now) return null;
        }

        let cat = '';
        let catId: number | null = null;
        if (d.Categories.length !== 0) {
          cat = d.Categories[0].slug;
          catId = d.Categories[0].id;
        }

        let icon = '';
        let color = '';
        const iconCat = categories.find((c: Category) => c.id === catId);
        if (iconCat) {
          icon = iconCat.icon;
          color = iconCat.color;
        }

        let selected = false;
        if (selectedMarker) {
          selected = selectedMarker.id === d.id;
        }

        return (
          <Marker
            key={`marker-${i}`}
            position={d.geo.coordinates as Coordinate}
            interactive={d.isInteractive}
            icon={markerIcon({
              category: cat,
              selected: selected,
              zicon: icon,
              color: color,
              id: d.id
            })}
            onClick={(): void => {
              handleClick({
                selectMarker,
                marker: d,
                map: map
              });
              // Only if not in iframe
              if (
                selectedSubzone &&
                window.location === window.parent.location
              ) {
                history.push(`/${selectedSubzone.slug}/${d.slug}`);
              }
            }}
          >
            {selected ? null : (
              <Tooltip direction="top" className={cat}>
                {d.i18n[currentLanguage]?.title || d.title}
              </Tooltip>
            )}
          </Marker>
        );
      })}
    </>
  );
};

export default Markers;
