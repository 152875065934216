import React, { useState } from 'react';
import { Zicon } from 'zoneatlas-icons';
import ReactMarkdown from 'react-markdown';

import Accordion from '../Accordion';
import { useCtx } from '../Context/';
import { useTranslation } from '../Context/Translations';
import { slugify } from '../../utils';
import CustomIcon from '../Icons';
import { TagType, SubzoneType, Category } from '../../types';
import { isMobile } from '../../utils';

type ListItemType = {
  category: TagType;
  onSelect: (clicked: TagType) => void;
  active: boolean;
};

// For front page
const ListItem = ({
  category,
  onSelect,
  active
}: ListItemType): JSX.Element => {
  const { translate } = useTranslation();
  return (
    <button
      className={`category btn filter ${active ? 'active' : ''}`}
      onClick={(): void => {
        // setActive(!active);
        onSelect(category);
      }}
    >
      <div
        className={`category__icon category_icon--${slugify(category.title)}`}
      >
        <CustomIcon icon={slugify(category.title)} />
      </div>
      {translate(slugify(category.title))}
      {active ? (
        <Zicon customClass="category__x" icon="ui/x-small" color="#015168" />
      ) : null}
    </button>
  );
};

type CategoryItemType = {
  category: Category;
};

// For subzone page
const CategoryItem = ({ category }: CategoryItemType): JSX.Element => {
  const { currentLanguage } = useTranslation();

  let color = '#015168';
  if (category.color) {
    color = category.color;
  }
  return (
    <span className="category">
      {category.icon ? (
        <Zicon
          icon={category.icon}
          customClass="category__icon"
          color={color}
        />
      ) : (
        <div className="category__icon"></div>
      )}
      {category.i18n[currentLanguage]?.title || category.title}
    </span>
  );
};

const Content = (): JSX.Element => {
  const {
    categories,
    activityTags,
    areaTags,
    selectedSubzone,
    setFilteredSubzones,
    subzones
  } = useCtx();
  const { translate, currentLanguage } = useTranslation();
  const [areaOpen, openArea] = useState(false);
  const [activityOpen, openActivity] = useState(!isMobile);
  const [filters, setFilter] = useState<number[]>([]);

  const filterSubzones = (clicked: TagType): void => {
    const newFilters = filters;
    // Filter already active
    if (filters.includes(clicked.id)) {
      const index = filters.findIndex(
        (elem: number): boolean => clicked.id === elem
      );
      newFilters.splice(index, 1);
      // Add filter to list
    } else {
      newFilters.push(clicked.id);
    }

    const newSubzones: SubzoneType[] = [];
    // Check one subzone a time
    for (let i = 0; i < subzones.length; i++) {
      let addSubzone = true;
      // Check for all tags in filter if subzone has the tag
      for (let k = 0; k < newFilters.length; k++) {
        const found = subzones[i].Tags.find(elem => newFilters[k] === elem.id);
        // If even one tag is not found in subzone, filter subzone away
        if (!found) {
          addSubzone = false;
        }
      }
      if (addSubzone) {
        newSubzones.push(subzones[i]);
      }
    }
    if (newSubzones.length === 0 && newFilters.length === 0) {
      setFilteredSubzones(subzones);
    } else {
      setFilteredSubzones(newSubzones);
    }
    setFilter(newFilters);
  };

  return !selectedSubzone ? (
    // Front page
    <>
      <button
        className="menu__subtitle"
        onClick={(): void => openArea(!areaOpen)}
      >
        {translate('menu-area-button')}
        <Zicon
          icon={areaOpen ? 'ui/chevron/small-up' : 'ui/chevron/small-down'}
        />
      </button>
      {areaOpen
        ? areaTags.map((d: TagType) => (
            <ListItem
              category={d}
              key={d.id}
              onSelect={filterSubzones}
              active={filters.includes(d.id)}
            />
          ))
        : null}
      <button
        className="menu__subtitle second"
        onClick={(): void => openActivity(!activityOpen)}
      >
        {translate('menu-activity-button')}
        <Zicon
          icon={activityOpen ? 'ui/chevron/small-up' : 'ui/chevron/small-down'}
        />
      </button>
      {activityOpen
        ? activityTags.map((d: TagType) => (
            <ListItem
              category={d}
              key={d.id}
              onSelect={filterSubzones}
              active={filters.includes(d.id)}
            />
          ))
        : null}
    </>
  ) : (
    // Subzone page
    <>
      <span className="menu__title">INFO</span>
      <Accordion
        title={
          <span className="menu__link">
            <Zicon icon="ui/info" color="#015168" />
            {translate('infobox-link-text')}
          </span>
        }
      >
        <ReactMarkdown linkTarget="_blank">
          {selectedSubzone.i18n[currentLanguage]?.description ||
            selectedSubzone.description}
        </ReactMarkdown>
      </Accordion>
      {categories.map((d: Category) => (
        <CategoryItem category={d} key={d.id} />
      ))}
      <span className="category">
        <Zicon icon="ui/area" customClass="category__icon" color="#00c000" />
        {translate('infobox-conservation-area')}
      </span>
    </>
  );
};

export default Content;
