import React, { useEffect, useRef, useState } from 'react';
import { Map, ZoomControl, Pane, ScaleControl } from 'react-leaflet';
import L, { LatLngTuple } from 'leaflet';
import { bbox, centroid, feature, featureCollection } from '@turf/turf';
import { Zicon } from 'zoneatlas-icons';
import { useRouteMatch } from 'react-router-dom';
import ReactDOMServer from 'react-dom/server';
import MarkerClusterGroup from 'react-leaflet-markercluster';
import { GestureHandling } from 'leaflet-gesture-handling';

import 'leaflet/dist/leaflet.css';
import 'leaflet-gesture-handling/dist/leaflet-gesture-handling.css';

import 'mapbox-gl-leaflet';

import './map.scss';

import UserLocateButton from './UserLocateButton';
import Markers from './Markers';
import Routes from './Routes';
import Areas from './Areas';
import Subzones from './Subzones';
import Message from '../Message';
import { useCtx } from '../Context';
import { SubzoneType, MapItem, Category } from '../../types';
import { isMobile, isDesktopSafari } from '../../utils';
import FullscreenButton from './FullscreenButton';

const defaultLatLng: LatLngTuple = [0.216685, 4.869079];
const defaultZoom = 15;

L.Map.addInitHook('addHandler', 'gestureHandling', GestureHandling);

const setTileLayer = (map: Map): void => {
  if (map) {
    L.mapboxGL({
      accessToken: '...',
      style: `${process.env.PUBLIC_URL}/mapbox-style.json`
    }).addTo(map.leafletElement);
  }
};

const Watermark = (): JSX.Element => (
  <a
    href="https://www.zoneatlas.com"
    target="_blank"
    rel="noopener noreferrer"
    className="watermark"
  >
    ZONEATLAS <span className="hidden-mobile">interactive maps</span>
  </a>
);

const generateClusterIconData = (
  // MarkerCluster here, but couldn't get to work
  cluster: any,
  prioCategories: string[]
): { slug: string | null; childCount: number } => {
  let slug: string | null = null;
  let iconIndex = prioCategories.length;
  const markers = cluster.getAllChildMarkers();

  // Should put here react-leaflet Marker, but is atm leaflet Marker
  markers.forEach((m: any) => {
    if (slug !== prioCategories[0]) {
      for (let i = 0; i < iconIndex; i++) {
        const category = m.options.children?.props.className;
        if (
          category &&
          category.indexOf(prioCategories[i]) !== -1 &&
          (!slug || (slug && iconIndex > i))
        ) {
          slug = prioCategories[i];
          iconIndex = i;
          break;
        }
      }
    }

    // If no prioritised categories in the cluster just pick the first
    if (slug === null && markers[0].options.children) {
      slug = markers[0].options.children.props.className;
    }
  });

  let childCount = cluster.getChildCount();
  if (slug && slug !== 'subzone-popup') {
    childCount -= 1;
  }

  return {
    slug,
    childCount
  };
};

const LeafletMap = (): JSX.Element => {
  const {
    settings,
    setMap,
    subzones,
    selectSubzone,
    selectedSubzone,
    markers,
    areas,
    routes,
    selectMarker,
    selectedMarker,
    categories,
    embed
  } = useCtx();
  const mapRef = useRef<Map>(null);
  const [classes, setClasses] = useState('');

  const [message, setMessage] = useState<string | null>(null);
  const [error, setError] = useState<boolean>(false);
  const match = useRouteMatch();

  // Inside the component to access categories from context
  const markerClusterIconCreate = (cluster: any): L.DivIcon => {
    const prioCategories = [
      'pysakointialue',
      'esteeton-tulipaikka',
      'nuotiopaikat',
      'tulentekopaikka',
      'tulipaikka',
      'keittokatos',
      'varaus-/vuokratulipaikka',
      'varauskota/-keittokatos',
      'taukopaikka',
      'infotaulu',
      'kuivakaymala'
    ];

    const { childCount, slug } = generateClusterIconData(
      cluster,
      prioCategories
    );
    const icon = categories.find((m: Category) => m.slug === slug)?.icon;
    const text = slug === 'subzone-popup' ? childCount : `+${childCount}`;
    const icon2 = ReactDOMServer.renderToString(
      <>
        {slug !== 'subzone-popup' ? (
          <Zicon icon={icon ? icon : ''} color="#fff" customClass="icon" />
        ) : null}
        <span className={`count ${slug === 'subzone-popup' ? 'subzone' : ''}`}>
          {text}
        </span>
      </>
    );

    return L.divIcon({
      html: `${icon2}`,
      className: `marker-cluster-${cluster._leaflet_id} ${icon} ${
        slug === 'esteeton-tulipaikka' ? 'secondary-group' : ''
      } marker-cluster`,
      iconSize: L.point(50, 50, true)
    });
  };

  const markerClusterProps = {
    spiderfyOnMaxZoom: false,
    showCoverageOnHover: false,
    zoomToBoundsOnClick: true,
    removeOutsideVisibleBounds: false,
    disableClusteringAtZoom: 17,
    maxClusterRadius: 30,
    chunkedLoading: true,
    iconCreateFunction: markerClusterIconCreate
  };

  useEffect(() => {
    // Set selected subzone to the current subzone
    if (match.path !== '/') {
      const selected = subzones.find(
        (s: SubzoneType) => s.slug === match.params['subzone']
      );
      selectSubzone(selected || null);
    }
  }, [match.path, match.params, selectSubzone, subzones]);

  useEffect(() => {
    // Set map ref, so other components can access it from the context
    if (mapRef && mapRef.current) {
      setMap(mapRef);
      setTileLayer(mapRef.current);
    }
  }, [mapRef, setMap]);

  useEffect(() => {
    if (markers.length !== 0) {
      const mapitem = match.params['mapitem'];
      if (mapitem) {
        const selected = markers.find((m: MapItem) => m.slug === mapitem);
        selectMarker(selected || null);
      }
    }
  }, [match.params, selectMarker, markers]);

  useEffect(() => {
    // Fit marker bounds if subzone selected
    if (
      selectedSubzone &&
      mapRef.current &&
      Object.keys(match.params).length === 1
    ) {
      const features1 = markers.map((m: MapItem) => feature(m.geo));
      const features2 = areas.map((m: MapItem) => feature(m.geo));
      const features3 = routes.map((m: MapItem) => feature(m.geo));
      const features = [...features1, ...features2, ...features3];

      if (!features.length) {
        return;
      }

      if (features.length > 0) {
        const bounds = bbox(featureCollection(features));
        // const currentZoom = mapRef.current.leafletElement.getCenter();
        const bounds2 = L.latLngBounds(
          [bounds[0], bounds[1]],
          [bounds[2], bounds[3]]
        );
        // Don't fly to bounds if we are already inside the bounds
        // if (!bounds2.contains(currentZoom)) {
        mapRef.current.leafletElement.flyToBounds(bounds2, {
          duration: 0.05
        });
        // }
      } else {
        const nums = centroid(selectedSubzone.geo).geometry;
        if (nums !== null) {
          const coords = [nums.coordinates[1], nums.coordinates[0]];
          mapRef.current.leafletElement.flyTo(coords as L.LatLngExpression, 14);
        }
      }
    } else if (
      // Else if subzone not selected fit subzones
      !selectedSubzone &&
      !markers.length &&
      mapRef.current &&
      subzones.length &&
      Object.keys(match.params).length === 0
    ) {
      if (subzones.length > 1) {
        const features = subzones.map((m: SubzoneType) => centroid(m.geo));
        const bounds = bbox(featureCollection(features));
        const latlangbox = [
          [bounds[1], bounds[0]],
          [bounds[3], bounds[2]]
        ];
        mapRef.current.leafletElement.fitBounds(
          latlangbox as L.LatLngBoundsExpression,
          { padding: [20, 20] }
        );
      } else {
        const nums = centroid(subzones[0].geo).geometry;
        if (nums !== null) {
          const coords = [nums.coordinates[1], nums.coordinates[0]];
          mapRef.current.leafletElement.flyTo(coords as L.LatLngExpression);
        }
      }
    }
  }, [selectedSubzone, areas, routes, markers, subzones, match.params]);

  useEffect(() => {
    if (selectedMarker) {
      setClasses('up');
    }
  }, [selectedMarker]);

  // Hide message after 4s
  useEffect(() => {
    if (message !== null) {
      const timer = setTimeout(() => setMessage(null), 4000);
      return (): void => clearTimeout(timer);
    }
    return;
  }, [message]);

  return (
    <Map
      id="mapId"
      ref={mapRef}
      center={settings.defaultMapCenter?.coordinates || defaultLatLng}
      zoom={settings.defaultMapZoomLevel || defaultZoom}
      maxZoom={22}
      whenReady={() => {
        // Check desktopsafari because it gesturehandler bugs with popups
        if (embed && !isDesktopSafari()) {
          (mapRef.current?.leafletElement as any).gestureHandling.enable();
        }
      }}
      tap={false}
      zoomControl={false}
      className={classes}
      scrollWheelZoom={!isMobile ? !embed : true}
    >
      <Watermark />
      <ScaleControl imperial={false} />
      {/* Edit message with message parameter */}
      <Message message={message} isError={error} />
      <ZoomControl position="bottomright" />
      <FullscreenButton />
      <UserLocateButton
        setError={setError}
        mapRef={mapRef}
        selectedSubzone={selectedSubzone}
        setMessage={setMessage}
      />
      {!selectedSubzone ? (
        <MarkerClusterGroup {...markerClusterProps}>
          <Subzones />
        </MarkerClusterGroup>
      ) : (
        /* These are shown only on subzone pages */
        <>
          {categories.length !== 0 ? (
            <MarkerClusterGroup key="withCategories" {...markerClusterProps}>
              <Markers />
            </MarkerClusterGroup>
          ) : (
            <MarkerClusterGroup key="withoutCategories" {...markerClusterProps}>
              <Markers />
            </MarkerClusterGroup>
          )}
          <Pane name="route" className="route-pane">
            <Routes />
          </Pane>
          <Pane name="area" className="area-pane">
            <Areas />
          </Pane>
        </>
      )}
    </Map>
  );
};

export default LeafletMap;
