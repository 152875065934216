import React from 'react';
import { Polyline } from 'react-leaflet';
import { latLngBounds, LatLngBounds } from 'leaflet';

import { useCtx } from '../../Context';
import { MapItem, MapRefType, Category } from '../../../types';
import './Routes.scss';
import '../Markers/Markers.scss';
import { isMobile } from '../../../utils';

type clickProps = {
  selectMarker: (route: MapItem) => void;
  item: MapItem;
  map: MapRefType | null;
  bounds: LatLngBounds;
};

const lineLayout = (
  category: string,
  selected: boolean,
  strokeWeight: number,
  dashArray: string,
  color: string
): object => {
  const layout = {
    fill: false,
    stroke: true,
    weight: strokeWeight,
    dashArray,
    color: `${color !== '' ? color : '#005E4E'}`,
    opacity: 1,
    noClip: true,
    className: `route line ${selected ? 'selected' : ''}  route--${category}`
  };
  if (selected) {
    layout.weight = 5;
    layout.dashArray = '0';
  }

  return layout;
};

/*
const waypointIcon = ({ category, selected, number }: iconProps): L.DivIcon => {
  const iconWidth = 50;
  const iconHeight = 50;
  const iconSize = [iconWidth, iconHeight];
  const iconAnchor = [iconWidth / 2, iconHeight];
  const icon = ReactDOMServer.renderToString(
    <>
      <Zicon icon="ui/location-blank" color="#2800ff" customClass="icon" />
      <span className="bg number">{number}</span>
    </>
  );
  const options: object = {
    className: `marker-icon waypoint-icon ${selected ? 'selected' : ''}`,
    html: `${icon}`,
    iconSize,
    iconAnchor
  };
  return L.divIcon(options);
};
*/

const handleClick = ({ selectMarker, item, map, bounds }: clickProps): void => {
  selectMarker(item);
  if (map && map.current) {
    const mapElem = map.current.leafletElement;
    let padding: [number, number] = [0, 0];

    if (isMobile) {
      // 'card' takes 60% of window height -> project coordinates to 40% window height.
      padding = [0, window.innerHeight * 0.6];
    }

    mapElem.fitBounds(bounds, { maxZoom: 18, paddingBottomRight: padding });
  }
};

const Routes = (): JSX.Element => {
  const strokeWeight = 3;
  const dashArray = '0';
  const { routes, selectMarker, selectedMarker, map, categories } = useCtx();

  return (
    <>
      {routes.map((r: MapItem, i: number) => {
        // const coords: L.LatLng[] = [];
        let selected = false;

        // L.geoJSON(r.geo, {
        //   coordsToLatLng: c => {
        //     let ll;
        //     if (c[0] < c[1]) {
        //       ll = new L.LatLng(c[1], c[0], c[2]);
        //       coords.push(ll);
        //     } else {
        //       ll = new L.LatLng(c[0], c[1], c[2]);
        //       coords.push(ll);
        //     }
        //     return ll;
        //   }
        // });

        // check if current time is more than r.activeTimeStart

        if (r.activeTimeStart && r.activeTimeEnd) {
          const now = new Date();
          const start = new Date(r.activeTimeStart);
          const end = new Date(r.activeTimeEnd);
          if (start > now) return null;
          if (end < now) return null;
        }

        const bounds = latLngBounds(r.geo.coordinates as any);
        let cat = '';
        let catId: number | null = null;
        if (r.Categories.length !== 0) {
          cat = r.Categories[0].slug;
          catId = r.Categories[0].id;
        }

        //let icon = '';
        let color = '';
        const iconCat = categories.find((c: Category) => c.id === catId);
        if (iconCat) {
          //icon = iconCat.icon;
          color = iconCat.color;
        }

        if (selectedMarker) {
          selected = selectedMarker.id === r.id;
        }

        const layout = lineLayout(
          cat,
          selected,
          strokeWeight,
          dashArray,
          color
        );
        const layout2 = {
          fill: false,
          stroke: true,
          weight: selected ? 10 : 20,
          dashArray,
          color: '#015168',
          opacity: selected ? 1 : 0,
          noClip: true,
          className: `route-line line`
        };

        return (
          <div key={i}>
            {/* Another polyline (not visible) for better clickability of the route */}
            <Polyline
              key={`line2-${i}`}
              positions={r.geo.coordinates as any}
              interactive={r.isInteractive}
              onClick={(): void =>
                handleClick({ selectMarker, item: r, map, bounds })
              }
              {...layout2}
            />
            <Polyline
              key={i}
              positions={r.geo.coordinates as any}
              interactive={r.isInteractive}
              onClick={(): void =>
                handleClick({ selectMarker, item: r, map, bounds })
              }
              {...layout}
            />
            {/*r.waypoints &&
          r.waypoints.map((w: waypointProps, i: number) => (
            <Marker
              icon={waypointIcon({
                category: 'waypoint',
                selected: selectedMarker && selectedMarker.id === w.id,
                number: i + 1
              })}
              onClick={(): void => {
                handleClick({ onClick: selectMarker, item: w, map });
              }}
              key={w.id}
              position={w.coordinates}
            >
              {selectedMarker && selectedMarker.id === w.id ? null : (
                <Tooltip direction="top" className="waypoint">
                  {w.title}
                </Tooltip>
              )}
            </Marker>
              ))*/}
          </div>
        );
      })}
    </>
  );
};

export default Routes;
