import { centroid } from '@turf/turf';

export const getPolygonCenter = (geojson: any) => {
  const center = centroid(geojson);
  return center.geometry ? center.geometry.coordinates : [0, 0];
};

export const isMobile = window.innerWidth <= 900;

export const isDesktopSafari = (): boolean => {
  const uA = navigator.userAgent;
  const vendor = navigator.vendor;
  return (
    /Safari/i.test(uA) &&
    /Apple Computer/.test(vendor) &&
    !/Mobi|Android/i.test(uA)
  );
};

export const slugify = (text: string): string =>
  text
    .toString()
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .toLowerCase()
    .trim()
    .replace(/\s+/g, '-')
    .replace(/[^\w-]+/g, '')
    .replace(/--+/g, '-');

export const isEmbed = (): boolean => {
  const urlParams =
    typeof URLSearchParams !== 'undefined'
      ? new URLSearchParams(window.location.search)
      : false;
  // Check is iframe
  let embed = false;
  try {
    embed = window.self !== window.top;
  } catch (e) {
    embed = urlParams && urlParams.get('embed') !== null;
  }

  return embed;
};
