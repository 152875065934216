import { ReactComponent as icon1 } from '../../images/icons/reitti-alle-3km.svg';
import { ReactComponent as icon2 } from '../../images/icons/latuhiihto.svg';
import { ReactComponent as icon3 } from '../../images/icons/helppo-kulkeminen.svg';
import { ReactComponent as icon4 } from '../../images/icons/retkipyoraily.svg';
import { ReactComponent as icon5 } from '../../images/icons/tulistelu.svg';
import { ReactComponent as icon6 } from '../../images/icons/kavely.svg';
import { ReactComponent as icon7 } from '../../images/icons/veneily.svg';
import { ReactComponent as icon8 } from '../../images/icons/melonta.svg';
import { ReactComponent as icon9 } from '../../images/icons/maastopyoraily.svg';
import { ReactComponent as icon10 } from '../../images/icons/kalastus.svg';
import { ReactComponent as icon11 } from '../../images/icons/uiminen.svg';
import { ReactComponent as icon12 } from '../../images/icons/reitti-yli-3km.svg';
import { ReactComponent as icon13 } from '../../images/icons/talviretkeily.svg';
import { ReactComponent as icon14 } from '../../images/icons/sienestys-ja-marjastus.svg';
import { ReactComponent as icon15 } from '../../images/icons/lintujen-tarkkailu.svg';

export default {
  'reitti-alle-3km': icon1,
  latuhiihto: icon2,
  'helppo-kulkeminen': icon3,
  retkipyoraily: icon4,
  tulistelu: icon5,
  kavely: icon6,
  veneily: icon7,
  melonta: icon8,
  maastopyoraily: icon9,
  kalastus: icon10,
  uiminen: icon11,
  'reitti-yli-3km': icon12,
  talviretkeily: icon13,
  'sienestys-ja-marjastus': icon14,
  'lintujen-tarkkailu': icon15
};
