import React, { useEffect } from 'react';
import { Zicon } from 'zoneatlas-icons';
import { useCtx } from '../Context';
import { isDesktopSafari } from '../../utils';

const fullscreenSupported = document.fullscreenEnabled;

const FullscreenButton = (): JSX.Element | null => {
  const { map, embed } = useCtx();
  const [isFullscreen, setFullscreen] = React.useState<boolean>(false);

  useEffect(() => {
    const onFullscreenChange = (): void => {
      if (document.fullscreenElement) {
        setFullscreen(true);
      } else {
        setFullscreen(false);
      }
    };

    document.addEventListener('fullscreenchange', onFullscreenChange);

    return (): void => {
      document.removeEventListener('fullscreenchange', onFullscreenChange);
    };
  }, []);

  // if embed, change gestureHandling
  useEffect(() => {
    if (map && embed && !isDesktopSafari()) {
      if (isFullscreen) {
        (map.current?.leafletElement as any).gestureHandling.disable();
      } else {
        (map.current?.leafletElement as any).gestureHandling.enable();
      }
    }
  }, [isFullscreen, embed, map]);

  const toggleFullScreen = (): void => {
    if (!document.fullscreenElement) {
      document.documentElement.requestFullscreen();
    } else if (document.exitFullscreen) {
      document.exitFullscreen();
    }
  };

  if (!fullscreenSupported) {
    return null;
  }

  return (
    <button
      onClick={toggleFullScreen}
      className={`fullscreen-btn ${
        isFullscreen ? 'fullscreen-btn--active' : ''
      }`}
    >
      <Zicon
        icon={isFullscreen ? 'ui/fullscreen-active' : 'ui/fullscreen'}
        customClass="fullscreen-btn__icon"
      />
    </button>
  );
};

export default FullscreenButton;
