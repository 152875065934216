import React from 'react';
import list from './icons';

type IconProps = {
  icon: string;
  color?: string;
  customClass?: string;
};

const CustomIcon = ({
  icon,
  color,
  customClass
}: IconProps): React.ReactElement | null => {
  const props = {
    className: `custom-icon ${customClass || ''}`,
    style: { color }
  };

  const Icon = list[icon];

  if (!Icon) {
    return null;
  }

  return React.createElement(Icon, props);
};

export default CustomIcon;
