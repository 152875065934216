import React, { useState } from 'react';
import { Zicon } from 'zoneatlas-icons';
import { centroid } from '@turf/turf';

import { useCtx } from '../../Context/';
import './searchBar.scss';
import { isMobile } from '../../../utils';
import { MapItem, SubzoneType } from '../../../types';
import { useTranslation } from '../../Context/Translations';

type ResultsProps = {
  results: Array<MapItem | SubzoneType>;
  onItemClick: (item: MapItem | SubzoneType) => void;
};

// Search result in list
const SearchResults = ({
  results,
  onItemClick
}: ResultsProps): JSX.Element | null => {
  const { currentLanguage } = useTranslation();
  const { selectedSubzone, selectMarker, map, selectedMarker } = useCtx();
  const itemOnClick = (
    item: MapItem | SubzoneType,
    recursive: boolean
  ): void => {
    if (map && map.current) {
      const mapElem = map.current.leafletElement;
      let layerId = null;
      const layers: L.Layer[] = [];
      mapElem.eachLayer((layer: L.Layer) => {
        layers.push(layer);
      });

      layerId = Object.keys(layers).find(
        (key: string) => layers[key].options.id === `mapobject-${item.id}`
      );

      if (
        recursive === false &&
        (typeof layerId === 'undefined' || layerId === null)
      ) {
        // Layer not found because it's out of mapbounds or in cluster
        // fly to target and recursively call this function once move/zoom ended
        let coordinates: any = [0, 0];

        if (item.geo.type === 'Polygon' || item.geo.type === 'LineString') {
          coordinates = centroid(item.geo).geometry?.coordinates || coordinates;
        } else if (item.geo.type === 'Point') {
          coordinates = item.geo.coordinates;
        }
        const currentZoom = mapElem.getZoom();
        mapElem.flyTo(coordinates, currentZoom <= 17 ? 17 : currentZoom, {
          duration: 0.2
        });

        mapElem.once('moveend', () => {
          itemOnClick(item, true);
          return;
        });
        return;
      }

      if (
        layerId !== undefined &&
        layerId !== null &&
        (selectedMarker === null || item.id !== selectedMarker.id)
      ) {
        const layer = layers[layerId];
        layer.fireEvent('click');

        if (layer._bounds) {
          mapElem.fitBounds(layer._bounds);
        }
      }
    }

    onItemClick(item);

    if (selectedSubzone !== null) {
      selectMarker(item);
    }
  };

  if (results.length === 0) {
    return null;
  }

  return (
    <div className="results-container" id="results-container">
      {results.map((d: MapItem | SubzoneType, i: number) => {
        return (
          <button
            className="btn"
            onClick={(): void => itemOnClick(d, false)}
            key={`${i}result`}
          >
            {d.i18n[currentLanguage]?.title || d.title}
          </button>
        );
      })}
    </div>
  );
};

// Searchbar component
type SearchProps = {
  search: (val: string) => Array<MapItem | SubzoneType> | [];
  toggleMenu: (val: boolean) => void;
  placeholderText: string;
  classes: string;
};

const SearchBar = ({
  search,
  toggleMenu,
  placeholderText,
  classes
}: SearchProps): JSX.Element => {
  const [results, setResults] = useState<Array<MapItem | SubzoneType> | []>([]);
  const searchInputRef = React.useRef<HTMLInputElement>(null);

  const doSearch = (value: string): void => {
    const a: Array<MapItem | SubzoneType> | [] = search(value);
    setResults(a);
  };

  const onItemClick = (item: MapItem | SubzoneType): void => {
    // Close main menu if on mobile
    if (isMobile) {
      toggleMenu(false);
    }

    // Set input value
    if (searchInputRef.current) {
      searchInputRef.current.value = item.title;
    }

    setResults([]);
  };

  return (
    <div className={`search-container ${classes}`}>
      <input
        ref={searchInputRef}
        type="text"
        className="search"
        id="search"
        placeholder={placeholderText}
        onChange={(e): void => doSearch(e.target.value)}
        onClick={(e: React.MouseEvent): void =>
          doSearch((e.target as HTMLInputElement).value)
        }
      ></input>
      <Zicon icon="ui/search" customClass="search-icon" />
      <SearchResults results={results} onItemClick={onItemClick} />
    </div>
  );
};

export default SearchBar;
