import React, { useEffect, useState, useCallback } from 'react';
import L from 'leaflet';

import { useTranslation } from '../Context/Translations';
import { Zicon } from 'zoneatlas-icons';
import { MapRefType, SubzoneType } from '../../types';

const divHereIcon = (): L.DivIcon => {
  const size: [number, number] = [15, 15];
  const anchor: [number, number] = [7.5, 7.5];
  const options = {
    className: 'here-icon',
    iconSize: size,
    iconAnchor: anchor
  };
  return L.divIcon(options);
};

type ButtonProps = {
  mapRef: MapRefType;
  selectedSubzone: SubzoneType | null;
  setMessage: React.Dispatch<React.SetStateAction<string | null>>;
  setError: React.Dispatch<React.SetStateAction<boolean>>;
};

const UserLocateButton = ({
  mapRef,
  selectedSubzone,
  setMessage,
  setError
}: ButtonProps): JSX.Element => {
  const [locationOn, setLocation] = useState(false);
  const [loading, setLoading] = useState(false);
  const [dot, setDot] = useState<L.Marker | null>(null);
  const [circle, setCircle] = useState<L.Circle | null>(null);
  const { translate } = useTranslation();

  // Initialise location dot & circle
  useEffect(() => {
    if (dot === null && circle === null) {
      const dotElem = L.marker(L.latLng(0, 0), {
        icon: divHereIcon(),
        zIndexOffset: 100,
        autoPan: false
      });

      const circleElem = L.circle(L.latLng(0, 0), { stroke: false });
      setDot(dotElem);
      setCircle(circleElem);
    }
  }, [dot, circle]);

  const removeUserLocation = useCallback((): void => {
    if (dot !== null && circle !== null) {
      dot.remove();
      circle.remove();
    }
    setLocation(false);

    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    mapRef.current?.leafletElement.off('locationerror', onLocationError);

    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    mapRef.current?.leafletElement.off('locationfound', onLocationFound);

    mapRef.current?.leafletElement.stopLocate();
  }, [circle, dot, mapRef]);

  useEffect(() => {
    if (selectedSubzone) {
      removeUserLocation();
    }
  }, [selectedSubzone, removeUserLocation]);

  const onLocationFound = useCallback(
    (e: L.LocationEvent): void => {
      const map = mapRef.current?.leafletElement;
      let fit = true;
      setLoading(false);
      if (map) {
        if (selectedSubzone) {
          const coords = [];
          for (let i = 0; i < selectedSubzone.geo.coordinates[0].length; i++) {
            coords.push([
              selectedSubzone.geo.coordinates[0][i][1],
              selectedSubzone.geo.coordinates[0][i][0]
            ]);
          }
          const polygon = L.polygon(coords);
          fit = polygon.getBounds().contains(e.latlng);
        }
        if (!fit) {
          setMessage(translate('current-location-outside'));
          setError(true);
          removeUserLocation();
        } else {
          const coords = e.latlng;
          const radius = e.accuracy / 2;
          // Prevent initial jumps in location with accuracy restrictions (20 km)
          if (e.accuracy < 20000 && dot && circle) {
            // Center view and show message only on first locationfound
            if (!map.hasLayer(dot)) {
              map.setView(coords, 17);
              setMessage(translate('current-location-success'));
            }
            dot.addTo(map);
            circle.addTo(map);

            setLocation(true);
            dot.setLatLng(coords);
            circle.setLatLng(coords);
            circle.setRadius(radius);
            setError(false);
          }
        }
      }
    },
    [
      circle,
      dot,
      mapRef,
      selectedSubzone,
      setError,
      setMessage,
      translate,
      removeUserLocation
    ]
  );

  const onLocationError = useCallback((): void => {
    setMessage(translate('current-location-error'));
    setError(true);
    setLoading(false);
    setLocation(false);
  }, [setError, setMessage, translate]);

  // Remove location
  useEffect(() => {
    if (!locationOn) {
      if (dot !== null && circle !== null) {
        dot.remove();
        circle.remove();
      }
      const map = mapRef.current?.leafletElement;
      if (map) {
        map.off('locationerror', onLocationError);
        map.off('locationfound', onLocationFound);

        map.stopLocate();
      }
    }
  }, [locationOn, dot, circle, mapRef, onLocationError, onLocationFound]);

  const centerOnLocation = (): void => {
    const map = mapRef.current?.leafletElement;
    if (dot && map) {
      const center = map.getCenter();
      const dot2 = dot.getLatLng();
      let ratio = 0.005;
      const zoom = map.getZoom();
      if (zoom >= 12 && zoom < 14) {
        ratio = 0.0005;
      } else if (zoom >= 14) {
        ratio = 0.00005;
      }

      if (
        center.lat < dot2.lat + ratio &&
        center.lat > dot2.lat - ratio &&
        center.lng < dot2.lng + ratio &&
        center.lng > dot2.lng - ratio
      ) {
        removeUserLocation();
      } else {
        map.setView(dot.getLatLng(), map.getZoom());
      }
    }
  };

  // Location tracking
  const getCurrentPosition = (): void => {
    const map = mapRef.current?.leafletElement;
    if (map && locationOn) {
      centerOnLocation();
    } else if (map && !locationOn) {
      setLoading(true);
      map.on('locationerror', onLocationError);
      map.on('locationfound', onLocationFound);
      map.locate({
        setView: false,
        maxZoom: 13,
        watch: true,
        maximumAge: 10000,
        enableHighAccuracy: true
      });
    }
  };

  return (
    <button
      className={`locate-btn ${locationOn ? 'active' : ''} ${
        loading ? 'locate-btn--loading' : ''
      }`}
      onClick={getCurrentPosition}
    >
      <Zicon
        icon={loading ? 'ui/circle' : 'ui/location-center'}
        color={loading ? '#aaa' : '#015168'}
      />
    </button>
  );
};

export default UserLocateButton;
